import React from 'react'
import { Tabs } from 'antd';
import { parse } from 'query-string';

export default function CenterFacilities({ data }) {
  const renderDiv = (itm) => {
    return (
      <div className="center-facilities">
        <div className="center-facilities-title">{itm.question_text}</div>
        <div className="center-facilities-content">
          <div className="center-facilities-content-card card">
            <div className="center-facilities-content-card-content">
              <div className="content-value">
                <div className="content-value-desc">{itm.response[0]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const headers = ['Clinic', 'Pharmacy', 'Lab', 'Radiology', 'Telehealth', 'Ophthalmology', 'Surgery', 'TA specific', 'Facility', 'Decentralized Clinical Trials', 'Vaccine Administration & Management']
  return (
    <div className="center-facilities-tabs sub-tags-area">
      <Tabs defaultActiveKey="0">
        {headers.map((i, idx) => {
          return (
            <Tabs.TabPane tab={i} key={idx}>
              {data.filter((f) => (parse(location.search).currentTab === 'parent_centers' ? f.parent_sub_category : f.child_sub_category === i)).length
                ? data.filter((f) => (parse(location.search).currentTab === 'parent_centers' ? f.parent_sub_category : f.child_sub_category === i)).map((itm) => {
                  return (
                    renderDiv(itm)
                  )
                }) : <div className="no-data-found-msg">No relevant data found</div>}
            </Tabs.TabPane>
          )
        })}
      </Tabs>

    </div>
  )
}
