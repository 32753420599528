import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { stringify } from 'query-string';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_CENSUS_DATA = 'GET_CENSUS_DATA';
const GET_CENSUS_DATA_CANCEL = 'GET_CENSUS_DATA_CANCEL';
const GET_CENSUS_DATA_SUCCESS = 'GET_CENSUS_DATA_SUCCESS';
const GET_CENSUS_DATA_FAILURE = 'GET_CENSUS_DATA_FAILURE';
const GET_CENSUS_DATA_RESET = 'GET_CENSUS_DATA_RESET';

// Auto Complete cancel action
export const getCensusDataCancelAction = () => ({
  type: GET_CENSUS_DATA_CANCEL,
});

export const getCensusDataResetAction = () => ({
  type: GET_CENSUS_DATA_RESET,
});
// Auto Complete action
export const getCensusDataAction = (payload) => ({
  type: GET_CENSUS_DATA,
  payload,
});

// Auto Complete Success action
const getCensusDataSuccess = (payload) => ({
  type: GET_CENSUS_DATA_SUCCESS,
  payload,
});

export const getCensusDataEpic = (actions$) => actions$.pipe(
  ofType(GET_CENSUS_DATA),
  debounceTime(500),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/getCensus?${stringify(action.payload)}`, 'get'),
  ).pipe(
    map((res) => getCensusDataSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_CENSUS_DATA_CANCEL))),
    catchError((err) => of({
      type: GET_CENSUS_DATA_FAILURE,
      payload: err,
    })),
  )),
);

export function getCensusDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CENSUS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_CENSUS_DATA_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_CENSUS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    case GET_CENSUS_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state;
  }
}
