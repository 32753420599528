import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Radio, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import MultiAutoSelect from '../AutoSuggest/MultiAutoSelect'
import MultiAutoSelectObj from '../AutoSuggest/MultiAutoSelectObj'
import { getautosuggestvalueAction } from '../AddProjectName/logic';
import { getSearchResultFilterAction } from '../SearchResults/logic';

const { Option } = Select;

export function RadioType({
  type, state, setState, index,
}) {
  const onChange = (e) => {
    setState((s) => {
      return [...s.slice(0, index), { type, data: e.target.value }, ...s.slice(index + 1)];
    });
  };

  useEffect(() => {
    if (type === 'Alliance' || type === 'Parexel Study Exists') {
      const data = state.data ? state.data : 'no'
      setState((s) => {
        return [...s.slice(0, index), { type, data }, ...s.slice(index + 1)];
      });
    }
  }, [type])
  return (
    <div className="radio-sec">
      <Radio.Group onChange={onChange} value={state.data === 'yes' || state.data === 'Yes' ? 'yes' : 'no'}>
        <Radio value="yes">Yes</Radio>
        <Radio value="no">No</Radio>
      </Radio.Group>
    </div>
  );
}

export function ListType({
  type, state, setState, index, fdata,
}) {
  const handleChange = (e) => {
    let val = e
    if (type === 'Protocol Number / Trial ID' && e.length && e[e.length - 1].search(',')) {
      const temp = val.pop()
      val = [...val, ...temp.split(/[, ]+/)]
    }
    setState((s) => {
      return [...s.slice(0, index), { type, data: val }, ...s.slice(index + 1)];
    });
  };

  const a = fdata.filter((itm) => {
    return itm.title === type
  })
  let data = [];
  if (a[0]?.is_searchable) {
    data = a[0].values
  }
  if (a[0]?.title === 'Tier' || a[0]?.title === 'Compliance') {
    data = a[0].values
  }

  return (
    <div className="select-input">
      <Select
        mode="tags"
        size="middle"
        placeholder={type === 'Protocol Number / Trial ID' ? 'Enter Protocol Number or Trial IDs with source prefix. For e.g. CIT_262455, SIMS_775' : 'Please select'}
        value={state.data}
        onChange={handleChange}
        style={{ width: '100%' }}
        className="select-input-tag"
      >
        {data.map((d) => <Option key={d}>{d}</Option>)}
      </Select>
    </div>
  );
}

export function TextType({
  type, state, setState, index,
}) {
  const handleChange = (e) => {
    const val = e.target.value;
    setState((s) => {
      return [...s.slice(0, index), { type, data: val }, ...s.slice(index + 1)];
    });
  };

  return (
    <div className="select-input">
      <Input
        size="middle"
        placeholder="Please enter"
        value={state.data}
        onChange={handleChange}
        style={{ width: '100%' }}
        maxLength="1000"
      />
    </div>
  );
}

export function RangeType({
  type, state, setState, index,
}) {
  const startRef = useRef(null);
  const endRef = useRef(null);

  const handleChange = () => {
    const regex = /^\d+$/
    let startVal = 0
    let endVal = 0
    if (regex.test(startRef.current.input.value)) {
      startVal = Number(startRef.current.input.value);
    }
    if (regex.test(endRef.current.input.value)) {
      endVal = Number(endRef.current.input.value);
    }
    setState((s) => {
      return [...s.slice(0, index),
        { type, data: { start: startVal, end: endVal } },
        ...s.slice(index + 1)];
    });
  };

  const getValue = (kvalue) => {
    if (state.data) {
      return state.data[kvalue];
    }
    return null
  };

  return (
    <div className="select-input-more">
      <div className="select-input">
        <Input
          size="middle"
          placeholder="Please enter range eg: 1-123"
          value={getValue('start')}
          defaultValue={getValue('start')}
          onChange={handleChange}
          style={{ width: '50%' }}
          ref={startRef}
        />
      </div>
      <div className="select-input">
        <Input
          size="middle"
          placeholder="Please enter range eg: 1-123"
          value={getValue('end')}
          defaultValue={getValue('end')}
          onChange={handleChange}
          style={{ width: '50%' }}
          ref={endRef}
        />
      </div>
    </div>
  );
}

export function RaceType({
  keyType, state, setState, index,
}) {
  const dispatch = useDispatch();
  const searchResultFilterData = useSelector((searchResult) => searchResult.searchResultFilterData);
  const checkData = state?.data
  const [hisp, setHisp] = useState((checkData && state?.data[0]));
  const [type, settype] = useState((checkData && state?.data[0]));
  const [comp, setComp] = useState((checkData && state?.data[0]));

  const handleChangeHisp = (value) => {
    setHisp((old) => {
      return { ...old, key: value };
    });
    const htype = {}
    let evalue;
    if (value === 'Non Hispanic') {
      evalue = 'non_hispanic'
    } else {
      evalue = value.toLowerCase()
    }
    htype[evalue] = true
    dispatch(getSearchResultFilterAction({
      dataset: 'us_census',
      type: 'race_ethnicity',
      filters: JSON.stringify(htype),
    }, {
      filter_type: 'race_ethnicity',
      key: 'race_ethnicity',
    }))
  };
  const handleChangeType = (value) => {
    settype((old) => {
      return { ...old, type: value };
    });
  };

  const handleChangeComparision = (value) => {
    setComp((old) => {
      return { ...old, comparison: value };
    });
  }

  const handleChange = (e) => {
    const val = e.target.value;
    setState((s) => {
      const newData = [...s.slice(0, index), {
        type: keyType,
        data: [{
          key: hisp.key, type: type.type, comparison: comp.comparison, value: Number(val),
        }],
      }, ...s.slice(index + 1)];
      return newData
    });
  };

  return (
    <div className="select-input-more">
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'Hispanic',
              label: 'Hispanic',
            },
            {
              value: 'Non Hispanic',
              label: 'Non Hispanic',
            },
          ]}
          value={hisp?.key}
          onChange={(e) => handleChangeHisp(e)}
        />
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          value={type?.type}
          onChange={(e) => handleChangeType(e)}
        >
          {searchResultFilterData.flag && searchResultFilterData?.data?.race_ethnicity?.map((res) => (
            <>
              <Option value={res.value}>
                {res.displayName}
              </Option>
            </>

          ))}
        </Select>
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'lt',
              label: 'is less than',
            },
            {
              value: 'gt',
              label: 'is greater than',
            },
          ]}
          value={comp?.comparison}
          onChange={(e) => handleChangeComparision(e)}
        />
      </div>
      <div className="select-input">
        <Input
          placeholder="Population Range"
          value={checkData && state?.data[0]?.value}
          onChange={(e) => handleChange(e)}
          style={{ width: '25%' }}
        />
      </div>
    </div>
  );
}

export function IncomeType({
  keyType, state, setState, index,
}) {
  const dispatch = useDispatch();
  const searchResultFilterData = useSelector((searchResult) => searchResult.searchResultFilterData);
  const checkData = state?.data
  const [hisp, setHisp] = useState((checkData && state?.data[0]));
  const [type, settype] = useState((checkData && state?.data[0]));
  const [comp, setComp] = useState((checkData && state?.data[0]));

  const handleChangeHisp = (value) => {
    setHisp((old) => {
      return { ...old, key: value };
    });
    const htype = {}
    htype[value.toLowerCase()] = true
    dispatch(getSearchResultFilterAction({
      dataset: 'us_census',
      type: 'income_by_insurance',
      filters: JSON.stringify(htype),
    }, {
      filter_type: 'income_by_insurance',
      key: 'income_by_insurance',
    }))
  };
  const handleChangeType = (value) => {
    settype((old) => {
      return { ...old, type: value };
    });
  };

  const handleChangeComparision = (value) => {
    setComp((old) => {
      return { ...old, comparison: value };
    });
  }

  const handleChange = (e) => {
    const val = e.target.value;
    setState((s) => {
      const newData = [...s.slice(0, index), {
        type: keyType,
        data: [{
          key: hisp.key, type: type.type, comparison: comp.comparison, value: Number(val),
        }],
      }, ...s.slice(index + 1)];
      return newData
    });
  };

  return (
    <div className="select-input-more">
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'Insured',
              label: 'Insured',
            },
            {
              value: 'Uninsured',
              label: 'Uninsured',
            },
          ]}
          value={hisp?.key}
          onChange={(e) => handleChangeHisp(e)}
        />
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          value={type?.type}
          onChange={(e) => handleChangeType(e)}
        >
          {searchResultFilterData.flag && searchResultFilterData?.data?.income_by_insurance?.map((res) => (
            <>
              <Option value={res.value}>
                {res.displayName}
              </Option>
            </>

          ))}
        </Select>
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'lt',
              label: 'is less than',
            },
            {
              value: 'gt',
              label: 'is greater than',
            },
          ]}
          value={comp?.comparison}
          onChange={(e) => handleChangeComparision(e)}
        />
      </div>
      <div className="select-input">
        <Input
          placeholder="Population Range"
          value={checkData && state?.data[0]?.value}
          onChange={(e) => handleChange(e)}
          style={{ width: '25%' }}
        />
      </div>
    </div>
  );
}

export function EmploymentType({
  keyType, state, setState, index,
}) {
  const checkData = state?.data
  const [hisp, setHisp] = useState((checkData && state?.data[0]));
  const [type, settype] = useState((checkData && state?.data[0]));
  const [label, setLabel] = useState((checkData && state?.data[0]));
  const [comp, setComp] = useState((checkData && state?.data[0]));

  const handleChangeHisp = (value) => {
    setHisp((old) => {
      return { ...old, key: value };
    });
  };

  const handleChangeLabel = (value) => {
    setLabel((old) => {
      return { ...old, label: value };
    });
  };

  const handleChangeType = (value) => {
    settype((old) => {
      return { ...old, type: value };
    });
  };

  const handleChangeComparision = (value) => {
    setComp((old) => {
      return { ...old, comparison: value };
    });
  }

  const handleChange = (e) => {
    const val = e.target.value;
    setState((s) => {
      const newData = [...s.slice(0, index), {
        type: keyType,
        data: [{
          key: hisp.key, type: type.type, label: label.label, comparison: comp.comparison, value: Number(val),
        }],
      }, ...s.slice(index + 1)];
      return newData
    });
  };

  return (
    <div className="select-input-more">
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'Labor Force',
              label: 'Labor Force',
            },
            {
              value: 'Not in Labor Force',
              label: 'Not In Labor Force',
            },
          ]}
          value={hisp?.key}
          onChange={(e) => handleChangeHisp(e)}
        />
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          value={type?.type}
          onChange={(e) => handleChangeType(e)}
          options={[
            {
              value: 'Employed',
              label: 'Employed',
            },
            {
              value: 'Unemployed',
              label: 'Unemployed',
            },
          ]}
        />
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'Insured',
              label: 'Insured',
            },
            {
              value: 'Uninsured',
              label: 'Uninsured',
            },
          ]}
          value={label?.label}
          onChange={(e) => handleChangeLabel(e)}
        />
      </div>
      <div className="select-input">
        <Select
          style={{
            width: '25%',
          }}
          placeholder="Please select"
          options={[
            {
              value: 'lt',
              label: 'is less than',
            },
            {
              value: 'gt',
              label: 'is greater than',
            },
          ]}
          value={comp?.comparison}
          onChange={(e) => handleChangeComparision(e)}
        />
      </div>
      <div className="select-input">
        <Input
          placeholder="Population Range"
          value={checkData && state?.data[0]?.value}
          onChange={(e) => handleChange(e)}
          style={{ width: '25%' }}
        />
      </div>
    </div>
  );
}

export function AutoSuggestType({
  type, state, setState, index, apiCall, apiName, cancelAction,
  resetAction, keyy, fdata,
}) {
  const [val, setVal] = useState(state.data || [])
  useEffect(() => {
    if (val.length > 0) {
      setState((s) => {
        return [...s.slice(0, index), { type, data: val }, ...s.slice(index + 1)];
      });
    } else {
      setState((s) => {
        return [...s.slice(0, index), { type, data: [] }, ...s.slice(index + 1)];
      });
    }
  }, [val]);

  return (
    <div className="select-input">
      {keyy === 'sponsor'
        ? (
          <MultiAutoSelectObj
            apiCall={apiCall}
            apiName={apiName}
            setValue={setVal}
            fvalue={state.data}
            cancelAction={cancelAction}
            resetAction={resetAction}
            keyy={keyy}
          />
        )
        : (
          <MultiAutoSelect
            apiCall={apiCall}
            apiName={apiName}
            setValue={setVal}
            fvalue={state.data}
            cancelAction={cancelAction}
            resetAction={resetAction}
            keyy={keyy}
            type={type}
            fdata={fdata}
          />
        )}
    </div>
  );
}

export function SearchableType({
  type, state, setState, index,
}) {
  const dispatch = useDispatch();
  const autosuggestdata = useSelector((store) => store.autosuggestdata);
  const handleOnCLick = () => {
    dispatch(
      getautosuggestvalueAction({
        type: 'regions',
      }),
    )
  }

  const handleChange = (e) => {
    setState((s) => {
      return [...s.slice(0, index), { type, data: e }, ...s.slice(index + 1)];
    });
    // setSelectedRegion(e)
  };

  const handleMosueClick = () => {
    dispatch(
      getautosuggestvalueAction({
        type: 'sub_regions',
      }),
    )
  }

  return (
    <div className="select-input-more">
      <div className="select-input">
        {type === 'Region' ? (
          <Select
            mode="tags"
            size="middle"
            placeholder="Please select regions"
            defaultValue={state.data}
            onChange={handleChange}
            onMouseEnter={handleOnCLick}
            style={{ width: '100%' }}
            className="select-input-tag"
          >
            {autosuggestdata?.data?.data?.map((d) => <Option key={d}>{d}</Option>)}
          </Select>
        ) : (
          <Select
            mode="tags"
            size="middle"
            placeholder="Please select sub-regions"
            defaultValue={state.data}
            onChange={handleChange}
            onMouseEnter={handleMosueClick}
            style={{ width: '100%' }}
            className="select-input-tag"
          >{autosuggestdata?.data?.data?.map((d) => <Option key={d}>{d}</Option>)}
          </Select>
        )}
      </div>
    </div>
  );
}

export function DatePickerComp({
  type, state, setState, index,
}) {
  const handleDateChange = (e) => {
    const val = Math.floor(new Date(e._d).getTime() / 1000)

    setState((s) => {
      return [...s.slice(0, index), { type, data: val }, ...s.slice(index + 1)];
    });
  }
  const getValue = () => {
    if (state.data) {
      return moment.unix(new Date(state.data), 'YYYY/MM/DD')
    }
    return undefined
  }
  return (
    <>
      <DatePicker
        size="large"
        onChange={handleDateChange}
        defaultValue={getValue}
        format="DD-MMM-YYYY"
      />
    </>
  )
}
