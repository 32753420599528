import React, {
  useEffect, useState,
} from 'react'
import { Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddCenterNameModal from '../../components/AddCenterNameModal';
import { getotherautosuggestvalueAction } from '../AddProjectName/logic';
import { getDatasetListResetAction } from '../SearchResults/logic';

export default function OtherResearchAutoSuggest({
  fetchOptions, Value, checkFlag, parentFlag, ...props
}) {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false)
  const autosuggestotherdata = useSelector((store) => store.autosuggestotherdata);
  const { Option } = Select;
  useEffect(() => {
    if (autosuggestotherdata.flag) {
      if (autosuggestotherdata.data.data.length) {
        const resp = autosuggestotherdata.data.data.map((itm) => {
          return (
            { label: itm.key, value: itm.value, address: itm.address }
          )
        })
        setOptions(resp)
        setFetching(false);
      } else {
        setFetching(true)
        setOptions([])
      }
    }
  }, [JSON.stringify(autosuggestotherdata)])

  const onOtherCenterChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getotherautosuggestvalueAction({
          query: e.trim(),
          type: 'sites',
          filters: JSON.stringify({ parent_flag: false }),
        }),
      )
    }
  }

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (v) => {
  //     fetchRef.current += 1;
  //     // const fetchId = fetchRef.current;
  //     setOptions([]);
  //     setFetching(true);
  //     // fetchOptions(value).then((newOptions) => {
  //     //   if (fetchId !== fetchRef.current) {
  //     //     // for fetch callback order
  //     //     return;
  //     onOtherCenterChangeInput(v)
  //   }

  //   return debounce(loadOptions, debounceTimeout);
  // }, [fetchOptions, debounceTimeout]);

  const handleOnChange = (itm) => {
    props.setValue(itm.map((a) => {
      return { label: a.label, value: a.value, key: a.key }
    }))
  }

  // useEffect(() => {
  //   console.log('check here', Value)
  //   if (Value?.length) {
  //     setShowValue(Value.map((itm) => {
  //       return itm.label
  //     }))
  //   }
  // }, [Value])

  return (
    <>
      <Select
        labelInValue
        mode={props.mode}
        placeholder={props.placeholder}
        style={props.style}
        id={props.id}
        filterOption={false}
        onSearch={onOtherCenterChangeInput}
        notFoundContent={fetching ? 'No matches found' : null}
//         value={
//           checkFlag === 'savedAnalysis' || checkFlag === 'fromMainPage' ? Value?.map((itm) => {
//             return { label: itm.key, value: itm.value };
//           }) : Value?.value?.map((itm) => {
//             return { label: itm.key, value: itm.value };
//           })
// }
        value={Value}
        // defaultValue={Value1}
        // defaultValue={Value1}
        // value={showValue}
        // {...props}
      // options={options}
      // dropdownRender={(val) => {
      //   const { label } = val;
      //   return <h1 style={{ color: 'red' }}> {label} </h1>
      // }}
      // onSelect={(value) => {
      //   // const val = JSON.parse(value);
      //   // setSearchVal(val.key);
      //   setValue(val.key, val.value)
      //   // SetDropDownOpen(true);
      // }}
        onChange={handleOnChange}
      >
        {options.map((itm) => {
          return (
            <Option className="autocompl-section-option" value={itm.value}>
              <div className="label-wrap">{itm.label}</div>
              <div className="address-wrap"><span className="label-title">Address :</span><span className="desc">{itm?.address?.address_line1 ? itm?.address?.address_line1 : '-'}</span></div>
              <div className="city-wrap"><span className="label-title">City:</span><span className="desc">{itm.address.city ? itm.address.city : '-'}</span></div>
              <div className="state-wrap"><span className="label-title">State:</span><span className="desc">{itm.address.state ? itm.address.state : '-'}</span></div>
              <div className="country-wrap"><span className="label-title">Country:</span><span className="desc">{itm.address.country ? itm.address.country : '-'}</span></div>
              <div className="zip-wrap"><span className="label-title">Zip:</span><span className="desc">{itm.address.zip ? itm.address.zip : '-'}</span></div>
            </Option>
          );
        })}
      </Select>
      <Button onClick={() => {
        setModalOpen(true)
        dispatch(getDatasetListResetAction())
      }}
      >
        <span className="search-icon-btn-other-center" />
      </Button>
      {
        modalOpen ? (
          <AddCenterNameModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          // setValue={setSearchValue}
            flag="otherResearch"
            setValue={props.setValue}
            Value={Value}
            checkFlag={checkFlag}
            parentFlag={parentFlag}
          />
        ) : ''
      }
    </>
  );
}
