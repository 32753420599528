import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import {
  getSitePiDetailsFromTrialAction,
  getSitePiDetailsFromTrialResetAction,
} from '../DeepDiveScreen/logic';
import { Tooltip, Tag } from 'antd';
import StarRatings from 'react-star-ratings';
import InputWithCopy from '../CopyToClipboard';


export default function CTDeepDiveCenterDetails() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([]);
  const [showKeysList, setKeysList] = useState([]);
  const { cid } = parse(location.search);
  let nctId;
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid;
  }
  const getSitePiDetailsFromTrialResponse = useSelector(
    (store) => store.getSitePiDetailsFromTrialResponse
  );

  useEffect(() => {
    dispatch(
      getSitePiDetailsFromTrialAction({
        clinical_id: nctId,
        tabname: 'Centers',
      })
    );
  }, []);

  useEffect(() => {
    if (getSitePiDetailsFromTrialResponse.flag) {
      setTabsTrialDataObj(getSitePiDetailsFromTrialResponse.data.data);
      dispatch(getSitePiDetailsFromTrialResetAction());
    }
  }, [JSON.stringify(getSitePiDetailsFromTrialResponse)]);

  useEffect(() => {
    if (Object.keys(tabsTrialDataObj).length > 0) {
      const keys = Object.keys(tabsTrialDataObj);
      setKeysList(keys);
    }
  }, [tabsTrialDataObj]);

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1;
    }
    if (value === 'Class 2') {
      return 2;
    }
    if (value === 'Class 1') {
      return 3;
    }
    return 0;
  };

  const tagTitle = {
    tier: 'Tier',
    compliance: 'Compliance',
    alliance: 'Alliance type',
    indications: 'Indications',
    total_number_of_popis: 'Total PIs',
    industry_studies: 'Industry studies',
    interventional_studies: 'Interventional studies',
    observational_studies: 'Observational studies',
    parexel_studies: 'Parexel studies',
    enrollment_subjects: 'Enrolled subjects',
    department: 'Department',
  };

  const getDatefromTimestamp = (d) => {
    return new Date(d * 1000).toUTCString().substring(4, 16)
  }

  return (
    <div className="no-data-inside channel-data ct-deepdive-center-data">
      <Loader
        loading={getSitePiDetailsFromTrialResponse.loading}
        error={getSitePiDetailsFromTrialResponse.error}
      >
        <div className="card-body-content">
          {showKeysList && showKeysList.length > 0 ? (
            showKeysList.map((item) => {
              return (
                <div className="card-body-sub-content" value="United States, Arizona">
                  <div className="sub-in-title">{item}</div>
                  {tabsTrialDataObj[item]?.map((itm) => {
                    return (
                      // <div className="sub-in-content">

                      //   <div className="sub-in-content">
                      //     <a
                      //       className="pi-wrap"
                      //       title={j.first_row}
                      //       target="_blank"
                      //       href={`/search/deepdives?${stringify({
                      //         query: j.first_row,
                      //         id: j.site_id,
                      //         type: 'SITE',
                      //       })}`}
                      //       rel="noreferrer noopener"
                      //     >

                      //       <div>{j.first_row}</div>
                      //     </a>
                      //     <span className="sub-in-title" style={{ float: 'right', paddingRight: '55%' }}>
                      //       {j.status}
                      //     </span>
                      //     <div>{j.second_row}</div>
                      //   </div>
                      // </div>
                      <div className={`card`}>
                        <div className="card-header">
                          <div className="card-title">
                            <span className="flag-icon-img" />
                            <a
                              target="_blank"
                              href={`/search/deepdives?${stringify({
                                ...parse(location.search),
                                id: itm.id,
                                type: itm.type,
                                currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                              })}`}
                              rel="noopener noreferrer"
                            >
                              <Tooltip
                                title={
                                  <div>
                                    <div>
                                      Last modified by:{' '}
                                      {itm.last_modified_by_user_name
                                        ? itm.last_modified_by_user_name
                                        : '-'}
                                    </div>
                                    <div>
                                      Last modified on:{' '}
                                      {itm.last_modified_on
                                        ? getDatefromTimestamp(itm.last_modified_on)
                                        : '-'}
                                    </div>
                                  </div>
                                }
                              >
                                {itm.name}
                              </Tooltip>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <div className="leftside">
                            <div className="person-details">
                              <div className="contact_name">
                                <div className="title-child">Address:</div>
                                {itm.address ? (
                                  <div className="content-child">
                                    {itm?.address?.address_line1 &&
                                      `${itm?.address?.address_line1}, `}
                                    {itm?.address?.address_line2 &&
                                      `${itm?.address?.address_line2}, `}
                                    {itm?.address?.city && `${itm?.address?.city}, `}
                                    {itm?.address?.state && `${itm?.address?.state}, `}
                                    {itm?.address?.country && `${itm?.address?.country}, `}
                                    {itm?.address?.zip}
                                  </div>
                                ) : (
                                  <div className="content-child no-data">No data available</div>
                                )}
                              </div>
                              <div className="emailofcontact">
                                <div className="title-child">Email:</div>
                                {itm.email && itm.email.length ? (
                                  <>
                                    <div className="content-child">
                                      {itm.email.join(', ')}
                                      <InputWithCopy text={itm} type="email" />
                                    </div>
                                  </>
                                ) : (
                                  <div className="content-child no-data">No data available</div>
                                )}
                              </div>
                              <div className="mobileOfcontact">
                                <div className="title-child">Phone:</div>
                                {itm.phone_number && itm.phone_number.length ? (
                                  <div className="content-child">
                                    {itm.phone_number.join(', ')}
                                  </div>
                                ) : (
                                  <div className="content-child no-data">No data available</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="right-side">
                            {
                              <div style={{ display: 'flex' }}>
                                <div> Parent Institution Name: </div>
                                {Object.keys(itm.parent_institution) && Object.keys(itm.parent_institution).length ? (
                                <a
                                  target="_blank"
                                  href={`/search/deepdives?${stringify({
                                    ...parse(location.search),
                                    id: itm.parent_institution.id,
                                    currentTab: 'parent_centers',
                                  })}`}
                                  rel="noopener noreferrer"
                                >&nbsp;{itm.parent_institution.name}
                                </a>
                              ) : (
                                <span className="value" style={{ fontSize: '14px', color: '#848484' }}>&nbsp;No data available</span>
                              )}
                              </div>
                            }
                            <div className="tags-data">
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.tier}</div>
                                  <div
                                    className="sizeOftag"
                                    role="presentation"
                                    // onClick={onTierClick}
                                  >
                                    {itm.tier}
                                  </div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.alliance}</div>
                                  <div className="sizeOftag">
                                    {itm.alliance_type ? itm.alliance_type : '-'}
                                  </div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.compliance}</div>
                                  <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">
                                    {tagTitle.total_number_of_popis}
                                  </div>
                                  <div className="sizeOftag">{itm.total_number_of_popis}</div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.industry_studies}</div>
                                  <div className="sizeOftag">{itm.industry_studies}</div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">
                                    {tagTitle.interventional_studies}
                                  </div>
                                  <div className="sizeOftag">{itm.interventional_studies}</div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">
                                    {tagTitle.observational_studies}
                                  </div>
                                  <div className="sizeOftag">{itm.observational_studies}</div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                                  <div className="sizeOftag">{itm.parexel_studies}</div>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                                  <span className="sizeOftag">{itm.enrollment_subjects}</span>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.department}</div>
                                  <span className="sizeOftag">
                                    {itm.department ? itm.department : '-'}
                                  </span>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">{tagTitle.indications}</div>
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      itm.indications && itm.indications.length
                                        ? itm.indications.join('; ')
                                        : '-'
                                    }
                                  >
                                    <span className="sizeOftag">
                                      {itm.indications && itm.indications.length
                                        ? itm.indications.join('; ')
                                        : '-'}
                                    </span>
                                  </Tooltip>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">Sources&nbsp;</div>
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      itm.sources && itm.sources.length
                                        ? itm.sources.join(', ')
                                        : '-'
                                    }
                                  >
                                    <span className="sizeOftag">
                                      {itm.sources && itm.sources.length
                                        ? itm.sources.join(', ')
                                        : '-'}
                                    </span>
                                  </Tooltip>
                                </div>
                              </Tag>
                              <Tag>
                                <div className="details-abt-tags">
                                  <div className="nameOfTag">Center Type</div>
                                  <Tooltip
                                    placement="bottom"
                                    title={itm.center_type ? itm.center_type : '-'}
                                  >
                                    <span className="sizeOftag">
                                      {itm.center_type ? itm.center_type : '-'}
                                    </span>
                                  </Tooltip>
                                </div>
                              </Tag>
                              {itm.pxl_center_sims_ids ? (
                                <Tag>
                                  <div className="details-abt-tags">
                                    <div className="nameOfTag">SIMS IDs&nbsp;</div>
                                    <Tooltip
                                      placement="bottom"
                                      title={
                                        itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                          ? itm.pxl_center_sims_ids.join(', ')
                                          : '-'
                                      }
                                    >
                                      <span className="sizeOftag">
                                        {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                          ? itm.pxl_center_sims_ids.join(', ')
                                          : '-'}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </Tag>
                              ) : null}
                              {itm.pxl_center_cit_ids ? (
                                <Tag>
                                  <div className="details-abt-tags">
                                    <div className="nameOfTag">Citeline IDs&nbsp;</div>
                                    <Tooltip
                                      placement="bottom"
                                      title={
                                        itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                          ? itm.pxl_center_cit_ids.join(', ')
                                          : '-'
                                      }
                                    >
                                      <span className="sizeOftag">
                                        {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                          ? itm.pxl_center_cit_ids.join(', ')
                                          : '-'}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </Tag>
                              ) : null}

                              {itm.landscape_id ? (
                                <Tag>
                                  <div className="details-abt-tags">
                                    <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                                    <Tooltip
                                      placement="bottom"
                                      title={itm.landscape_id ? itm.landscape_id : '-'}
                                    >
                                      <span className="sizeOftag">
                                        {itm.landscape_id ? itm.landscape_id : '-'}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </Tag>
                              ) : null}
                            </div>
                            <div className="reliability-quality-rating">
                              <Tooltip
                                title={
                                  itm.reliability ? `Reliability score : ${itm.reliability}` : '-'
                                }
                              >
                                <div className="reliability rating-sec">
                                  <div className="rating-sec-title">Reliability</div>

                                  {itm.reliability_class && itm.reliability_class !== '' ? (
                                    <StarRatings
                                      rating={getStars(itm.reliability_class)}
                                      starRatedColor="#8A0050"
                                      numberOfStars={3}
                                      starDimension="16px"
                                      starSpacing="1px"
                                      starEmptyColor="#D099B9"
                                    />
                                  ) : (
                                    <div className="content-child no-data">
                                      &nbsp;No data available
                                    </div>
                                  )}
                                </div>
                              </Tooltip>
                            </div>
                          </div>

                          <div></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className="no-data-inside-ct">No data found</div>
          )}
        </div>
      </Loader>
    </div>
  );
}
