import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  RadioType, ListType, TextType, AutoSuggestType, SearchableType, DatePickerComp, RangeType, RaceType, IncomeType, EmploymentType,
} from './filter';
import {
  getautosuggestvalueAction, getautosuggestionCancelAction,
  getautosuggestionResetAction,
} from '../AddProjectName/logic';
import { getFilterValuesAction } from './logic'

const { Option } = Select;

const AddFilters = ({ forms, filters }) => {
  const dispatch = useDispatch();
  const onIndicationChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
          dataset: 'queries',
        }),
      )
    }
  }

  const onSponsorChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'sponsors',
        }),
      )
    }
  }

  const onSpecialityChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'specialities',
        }),
      )
    }
  }
  const onNetworkChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'networks_names',
          dataset: 'networks',
        }),
      )
    }
  }

  const onAllianceNameChange = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'alliance_name',
        }),
      )
    }
  }

  const onParentNameChange = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'parent_names',
          parent_flag: true,
        }),
      )
    }
  }

  const onCenterNetworkNameChange = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'center_networks',
          dataset: 'networks',
        }),
      )
    }
  }

  const onCenterName = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'center_name',
        }),
      )
    }
  }

  const onIndicationFromTrialsChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      const taData = formState.filter((f) => f.type === 'Therapy Area')
      if (taData && taData.length > 0) {
        dispatch(
          getautosuggestvalueAction({
            query: e.trim(),
            type: 'indications_from_trials',
            dataset: 'projects',
            ta_list: taData[0].data.join('|'),
          }),
        )
      } else {
        dispatch(
          getautosuggestvalueAction({
            query: e.trim(),
            type: 'indications_from_trials',
            dataset: 'queries',
          }),
        )
      }
    }
  }

  const getFilterValues = useSelector((store) => store.getFilterValues);

  const { formState, setFormState } = forms;
  const { filterValues, setFilterValues } = filters;
  const { selectedRegion, setSelectedRegion } = useState('')

  useEffect(() => {
    if (getFilterValues?.data && getFilterValues.flag) {
      setFilterValues(getFilterValues.data)
    }
  }, [getFilterValues])

  const getData = (f, idx) => {
    switch (f.type) {
      case 'Therapeutic Interest':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onIndicationChangeInput}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            key="indication"
          />
        );
      case 'Alliance Name':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onAllianceNameChange}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="alliance_name"
          />
        )

      case 'Parent Institution Names':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onParentNameChange}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="parent_names"
          />
        )

      case 'Center Network Names':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onCenterNetworkNameChange}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="center_networks"
          />
        )

      case 'Center Name':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onCenterName}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="center_name"
          />
        )

      case 'Indications From Trials':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onIndicationFromTrialsChangeInput}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="indications_from_trials"
          />

        );
      case 'Study Phase':
      case 'Country':
      case 'Alliance Type':
      case 'PI Source':
      case 'PI Name':
      case 'Parexel Study ID':
      case 'Age Range Group':
      case 'Profile Tier':
      case 'specific Tier':
      case 'Compliance':
      case 'Primary Center Type':
      case 'Protocol Number / Trial ID':
      case 'Therapy Area':
        return (
          <ListType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );

      case 'Alliance':
      case 'Parexel Study Exists':
        return (
          <RadioType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );

      case 'Sub-Region':
      case 'Region':
        return (
          <SearchableType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
          />
        );

      case 'Speciality':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onSpecialityChangeInput}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="speciality"
          />
        );
      case 'Protocol Title':
      case 'Study IDs':
        return (
          <TextType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );
      case 'Sponsor':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onSponsorChangeInput}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="sponsor"
          />
        );

      case 'Start Date':
      case 'End Date':
        return (
          <div className="select-input">
            <DatePickerComp
              type={f.type}
              index={idx}
              fdata={filterValues}
              setState={setFormState}
              state={f}
            />
          </div>
        );

      case 'Race & Ethnicity':
        return (
          <RaceType
            keyType={f.type}
            state={f}
            setState={setFormState}
            index={idx}
          />
        );
      case 'Employment by Insurance':
        return (
          <EmploymentType
            keyType={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );
      case 'Income by Insurance':
        return (
          <IncomeType
            keyType={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );
      case 'Investigator Network Names':
        return (
          <AutoSuggestType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
            apiCall={onNetworkChangeInput}
            apiName="autosuggestdata"
            setValue=""
            cancelAction={getautosuggestionCancelAction}
            resetAction={getautosuggestionResetAction}
            keyy="networks"
          />
        );

      case 'Start Up Months':
        return (
          <RangeType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );
      case 'Industry Studies':
      case 'Kol Score':
        return (
          <div className="select-input-more">
            <div className="select-input">
              <Select
                style={{
                  width: '25%',
                }}
                placeholder="Please select"
                options={[
                  {
                    value: 'Greater Than',
                    label: 'Greater Than',
                  },
                ]}
                value="Greater Than"
              />
            </div>
            <div className="select-input">
              <TextType
                style={{
                  width: '50%',
                }}
                type={f.type}
                state={f}
                setState={setFormState}
                index={idx}
                fdata={filterValues}
              />
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  const handleAdd = () => {
    setFormState((s) => [...s, { type: '' }]);
  };

  const handleRemove = (index) => {
    setFormState((s) => {
      const oldState = [...s];
      oldState.splice(index, 1);
      return [...oldState];
    });
  };

  useEffect(() => {
    if (formState && formState.length < 1) {
      handleAdd();
    }
  }, [formState]);

  const handleDropdown = (idx, value) => {
    const taData = formState.filter((f) => f.type === 'Therapy Area')
    if (taData && taData.length > 0 && value === 'Indications From Trials') {
      dispatch(getFilterValuesAction({
        ta_list: taData[0].data.join(', '),
      }));
    }
    setFormState((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const isDropDownDisabled = (key) => {
    const isRegionSelected = formState?.some((itm) => {
      return itm.type === 'Region'
    })
    return formState?.filter((a) => {
      if (key === 'Sub-Region' && !isRegionSelected) {
        return true
      }
      return key === a.type
    }).length;
  };

  const dropData = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdown(idx, e)}
        >
          {filterValues && filterValues.map((l, index) => {
            return (
              <Option key={index} value={l.title} disabled={isDropDownDisabled(l.title)}>
                {l.title}
              </Option>
            );
          })}
        </Select>

        <div>{getData(f, idx)}</div>
      </>
    );
  };

  return (
    <div className="filter-wrapper">
      {formState && formState.map((f, idx) => {
        return (
          <div key={idx} className="newfilter">
            <div className="filter-sec">
              {dropData(f, idx)}
              <div className="filter-sec-input">
                {formState.length > 1 && <Button onClick={() => handleRemove(idx)} className="plus-btn minus-btn">-</Button>}
                {idx === formState.length - 1 && (
                <Button onClick={handleAdd} className="plus-btn">+</Button>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AddFilters;
