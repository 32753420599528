import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_COPY_PROJECT = 'GET_COPY_PROJECT'
const GET_COPY_PROJECT_SUCCESS = 'GET_COPY_PROJECT_SUCCESS'
const GET_COPY_PROJECT_RESET = 'GET_COPY_PROJECT_RESET'
const GET_COPY_PROJECT_FAILURE = 'GET_COPY_PROJECT_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

// copy project
export const copyProjectAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_COPY_PROJECT,
    url: `${process.env.apiUrl}v0/projects/copyProject?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: meta.status_flag === true ? 'Process to copy the project with result has been commenced. You will be notified once the process is completed and the copied project is been available in "My Projects"' : 'Project copied successfully',
  hideNotification: false, // hide notification on error
})

export function copyProjectReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COPY_PROJECT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COPY_PROJECT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COPY_PROJECT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_COPY_PROJECT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
